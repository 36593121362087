<template>
  <div class="station-container">
    <a-page-header title="健康小站管理">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
    </a-page-header>
    <DataTable @edit="handleEdit" @delete="handleDel" :data="hubs" />
    <a-modal
      v-model:visible="viewerDel"
      title="註銷健康小站"
      @ok="handleOk"
      okType="danger"
      @cancel="handleCancel"
    >
      如確認要註銷此小站，請在下方輸入使用者密碼以確認非手誤動作。
      <a-row :gutter="[16, 10]" style="margin-top: 30px">
        <a-col :span="24">
          <a-form-item label="使用者帳號">
            {{ form.id }}
          </a-form-item>
          <a-form-item label="使用者密碼">
            <a-input-password v-model:value="form.password"></a-input-password>
          </a-form-item>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
  import { ref, reactive, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { QuestionCircleOutlined } from '@ant-design/icons-vue'
  import { notification } from 'ant-design-vue'
  import DataTable from './DataTable'
  import { login } from '@/api/user'
  import { delStation, getStation, logoffStation } from '@/api/store'

  export default {
    components: {
      QuestionCircleOutlined,
      DataTable,
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const viewerDel = ref(false)
      const hubs = ref([])
      let delStationInfo = {}
      const userInfo = store.getters['user/userInfo']
      const storeList = store.getters['store/storeList']

      onMounted(async () => {
        getHubs()
      })

      const getHubs = async () => {
        try {
          const { data, status } = await getStation()
          if (![200, 201].includes(status)) throw '查詢失敗'
          if (!data.ok) throw '查詢失敗'
          hubs.value = data.data
            .filter((item) => {
              return !['LOGGEDOFF'].includes(item.state)
              // return item.state == 'LOGGEDOFF'
            })
            .map((item) => ({
              ...item,
              area_name: getStoreInfo(item.store.storeID),
              area_sub_name: getStoreInfo(item.store.storeID, 2),
              store_name: getStoreInfo(item.store.storeID, 3),
              isActiveText: item.isActive ? '線上' : '離線',
            }))
            .sort((a, b) => (a.store_name > b.store_name ? 1 : -1))
        } catch (error) {
          notification.error({
            message: error,
          })
        }
      }

      const form = reactive({
        id: userInfo.account,
        password: '',
      })

      const handleEdit = (stationID) => {
        router.push({ path: `/setup/station/${stationID}` })
      }
      const handleDel = (stationInfo) => {
        delStationInfo = stationInfo
        viewerDel.value = true
      }

      const handleOk = async () => {
        try {
          const { status, data } = await login({ ...form })
          form.password = ''
          if (![200, 201].includes(status)) throw '密碼驗證失敗'
          if (!data.found) throw '密碼驗證失敗'

          // 如果狀態為註銷中(LOGGINGOFF)，則強制註銷
          if (delStationInfo.state === 'LOGGINGOFF') {
            const { data: resultData } = await delStation({
              hubCode: delStationInfo.stationID,
              permanently: true,
            })
            if (!resultData.ok) throw resultData.result
          } else {
            const { data: resultData } = await logoffStation({
              hubCode: delStationInfo.stationID,
            })
            if (!resultData.ok) throw resultData.result
          }
          getHubs()
          viewerDel.value = false
          notification.success({
            message: '註銷成功',
          })
        } catch (error) {
          notification.error({
            message: error,
          })
        }
      }
      const handleCancel = () => {
        form.password = ''
      }

      // 以門市ID取得門市資訊
      const getStoreInfo = (store_id, level = 1) => {
        if (store_id === null) return '未配置'
        const data = storeList.find((item) => item.store_id == store_id)

        if (typeof data == 'undefined') return '查無資料'
        if (level == 1) return data.area_name
        if (level == 2) return data.area_sub_name
        if (level == 3) return data.store_name
        return '錯誤'
      }
      return {
        viewerDel,
        hubs,
        form,
        handleEdit,
        handleDel,
        handleOk,
        handleCancel,
      }
    },
  }
</script>

<style></style>
