<template>
  <div class="station-component">
    <a-row
      type="flex"
      justify="end"
      align="middle"
      id="tableAction"
      style="margin-top: 6px"
    >
      <a-button type="primary" v-show="false">匯出(.xlsx)</a-button>
      <div v-if="hubs.length > 0" style="margin-right: 200px">
        總共筆數：{{ hubs.length }}
      </div>
    </a-row>
    <a-table
      :pagination="tableSetting"
      :columns="tableColumns"
      :data-source="hubs"
      @change="handleChange"
      :row-key="(record) => record.stationID"
      :rowClassName="
        (record) => (record.state === 'LOGGINGOFF' ? 'loggingoff' : '')
      "
    >
      <template #name="{ record }">
        <span>
          <a @click="handleEdit(record.id)">{{ record.title }}</a>
        </span>
      </template>
      <template #action="{ text }">
        <a-list size="small">
          <!-- <a-list-item>
            <a @click="handleEdit(text.stationID)">門市配置</a>
          </a-list-item> -->
          <a-list-item>
            <a v-if="text.state !== 'LOGGINGOFF'" @click="handleDelete(text)">
              註銷
            </a>
            <a v-else @click="handleDelete(text)">強制註銷</a>
          </a-list-item>
        </a-list>
      </template>
    </a-table>
  </div>
</template>

<script>
  import { reactive, watch, computed, ref } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  export default {
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const path = route.path
      const hubs = computed(() => props.data)
      let storeAreaFilter = []
      const tableVar = computed(() => {
        return store.getters['tableVar/tableVar'].find(
          (item) => item.path == path
        )
      })

      const tableColumns = ref([])
      const filteredInfo = computed(() => tableVar.value?.filteredInfo)
      const sortedInfo = computed(() => tableVar.value?.sortedInfo)

      const tableSetting = reactive({
        currentPage: 1,
        pageSize: 20,
        position: 'top',
        simple: true,
      })

      const handleEdit = (id) => {
        emit('edit', id)
      }
      const handleDelete = (id) => {
        emit('delete', id)
      }

      const setTableColumns = () => {
        const sorted = sortedInfo.value || {}
        const filtered = filteredInfo.value || {}
        tableColumns.value = [
          {
            title: '健康小站編號',
            dataIndex: 'stationID',
            key: 'stationID',
            width: '10%',
            align: 'center',
            sorter: (a, b) => (a.stationID > b.stationID ? 1 : -1),
            sortOrder: sorted.columnKey === 'stationID' && sorted.order,
          },
          {
            title: '大分區',
            dataIndex: 'area_name',
            key: 'area_name',
            align: 'center',
            filters: storeAreaFilter,
            onFilter: (value, record) => record.area_name.indexOf(value) === 0,
            sorter: (a, b) => (a.area_name > b.area_name ? 1 : -1),
            filteredValue: filtered.area_name || null,
            sortOrder: sorted.columnKey === 'area_name' && sorted.order,
          },
          {
            title: '小分區',
            dataIndex: 'area_sub_name',
            key: 'area_sub_name',
            align: 'center',
          },
          {
            title: '配置門市',
            dataIndex: 'store_name',
            key: 'store_name',
            align: 'center',
            sorter: (a, b) => (a.store_name > b.store_name ? 1 : -1),
            sortOrder: sorted.columnKey === 'store_name' && sorted.order,
          },
          {
            title: '連線狀態',
            dataIndex: 'isActiveText',
            key: 'isActiveText',
            align: 'center',
            filters: [
              {
                text: '離線',
                value: '離線',
              },
              {
                text: '線上',
                value: '線上',
              },
            ],
            filteredValue: filtered.isActiveText || null,
            onFilter: (value, record) =>
              record.isActiveText.indexOf(value) === 0,
            sorter: (a, b) => (a.isActiveText > b.isActiveText ? 1 : -1),
            sortOrder: sorted.columnKey === 'isActiveText' && sorted.order,
          },
          {
            title: '動作',
            width: 130,
            key: 'action',
            align: 'center',
            slots: { customRender: 'action' },
          },
        ]
      }

      const handleChange = (pagination, filters, sorter) => {
        store.dispatch('tableVar/setSortedInfo', { path, sortedInfo: sorter })
        store.dispatch('tableVar/setfilteredInfo', {
          path,
          filteredInfo: filters,
        })
        setTableColumns()
      }

      watch(
        () => props.data,
        () => {
          storeAreaFilter = Array.from(
            new Set(hubs.value.map((i) => i.area_name))
          )
            .map((item) => ({
              text: item,
              value: item,
            }))
            .sort((a, b) => (a.text > b.text ? 1 : -1))
          setTableColumns()
        }
      )

      return {
        tableSetting,
        handleDelete,
        tableColumns,
        handleEdit,
        hubs,
        handleChange,
      }
    },
  }
</script>

<style lang="less">
  .station-component {
    .ant-pagination.ant-table-pagination {
      text-align: right;
      margin: -25px 0 0 0;
    }
    .ant-table-tbody > tr > td {
      padding: 0px;
    }
    .loggingoff {
      color: red;
    }
  }
</style>
