import { render } from "./DataTable.vue?vue&type=template&id=35842140"
import script from "./DataTable.vue?vue&type=script&lang=js"
export * from "./DataTable.vue?vue&type=script&lang=js"

import "./DataTable.vue?vue&type=style&index=0&id=35842140&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "35842140"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('35842140', script)) {
    api.reload('35842140', script)
  }
  
  module.hot.accept("./DataTable.vue?vue&type=template&id=35842140", () => {
    api.rerender('35842140', render)
  })

}

script.__file = "src/views/setting/station/DataTable.vue"

export default script